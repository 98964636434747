import * as React from 'react';

export const createContext = <CtxType>(
  displayName: string,
  defaultCtx?: CtxType,
): [React.Provider<CtxType>, () => CtxType] => {
  const Context = React.createContext(defaultCtx as CtxType);

  Context.displayName = displayName;

  function useContext() {
    const ctx = React.useContext(Context);
    if (ctx === undefined) {
      throw new Error(
        `${displayName} consumer must be used within ${displayName}.Provider`,
      );
    }
    return ctx;
  }
  return [Context.Provider, useContext];
};
